<template>
  <div class="index">
    <!-- 轮播区块 -->
    <div class="swiper">
      <div class="swiper-box">
        <swiper :options="swiperOption">
          <!-- 循环想 -->
          <swiper-slide v-for="(item, index) in this.swiperList" :key="index">
            <a href="javascript:;"><img :src="item.img" /></a>
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
          <div class="swiper-button-prev" slot="button-prev"></div>
          <div class="swiper-button-next" slot="button-next"></div>
        </swiper>
      </div>
    </div>

    <!-- 关于我们区块 -->
    <div class="us-con">
      <div class="w">
        <!-- 右侧 -->
        <div class="us-r">
          <img src="/imgs/us.jpg" alt="" />
        </div>
        <!-- 左侧 -->
        <div class="us-l">
          <h2>湖南爱善天使互联网有限公司</h2>
          <h3>ABOUT US</h3>
          <p>

            经营范围包括许可项目：互联网信息服务；药品互联网信息服务；第二类增值电信业务；食品销售；食品互联网销售。（依法须经批准的项目，经相关部门批准后方可开展经营活动，具体经营项目以相关部门批准文件或许可证件为准）一般项目：信息咨询服务（不含许可类信息咨询服务）；信息技术咨询服务；网络技术服务；技术服务、技术开发、技术咨询、技术交流、技术转让、技术推广；数据处理和存储支持服务；广告制作；广告设计、代理；广告发布；非居住房地产租赁；货物进出口；市场营销策划；互联网销售（除销售需要许可的商品）；第二类医疗器械销售。
          </p>
          <!-- <div class="us-btn">查看更多</div> -->
        </div>
      </div>
    </div>

    <!-- 容器 -->
    <div class="yp-con">
      <!-- 标题 -->
      <div class="container">
        <div class="index-title">
          <h4>Drug display center</h4>
          <h2>药品中心</h2>
        </div>
      </div>

      <div class="my-container">
        <!-- 循环项 -->
        <div class="yp-item" v-for="(item, index) in this.my_data" :key="index" @click="goDetails(item.id)">
          <!-- 图片 -->
          <div class="item-img">
            <img :src="item.img" alt="" />
          </div>
          <p>{{ item.title }}</p>
        </div>
      </div>
      <!-- 产看更多 -->
      <a href="/#/ac1" class="yc-btn">更多药品 <span>></span></a>
    </div>

    <!-- 资讯区块2 -->
    <div class="a2">
      <div class="container">
        <!-- 右侧 -->
        <div class="a2-r">
          <!-- 标题 -->
          <div class="r-title">
            <h2>医药资讯</h2>
          </div>

          <!-- 循环项 -->
          <div class="r-item" v-for="(item, index) in this.mdata" :key="index" @click="goDetails2(item.id)">
            <!-- 右侧信息 -->
            <div class="item-info">
              <div class="blink"></div>
              <span><i class="iconfont icon-zuozhe"></i> {{ item.author }}</span>
              <span><i class="iconfont icon-time"></i>{{ item.time }}</span>
              <span> <i class="iconfont icon-dianzan"></i> {{ item.sub }} </span>
              <!-- 标题 -->
              <h2>{{ item.title }}</h2>
              <!-- 简介 -->
              <p>
                {{ item.jj }}
              </p>
            </div>

            <!-- 左侧图片 -->
            <div class="item-img">
              <img :src="item.img" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import navigation from "./../components/navigation";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

export default {
  components: { Swiper, SwiperSlide },
  data() {
    return {
      mdata: [],
      data2: [],
      // 总页数
      pages: 6,
      // 当前页
      pageNo: 1,
      // 点击页数
      curPage: 1,
      // 根据页数获取数据
      my_data: [],
      // 轮播配置
      swiperOption: {
        autoplay: true,
        loop: true,
        effect: "fade",
        cubeEffect: {
          shadowOffset: 100,
          shadowScale: 0.6,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      // 轮播数组
      swiperList: [
        {
          id: "1",
          img: "/imgs/n1.jpg",
        },
        {
          id: "2",
          img: "/imgs/n2.jpg",
        },
      ],
    };
  },
  mounted() {
    document.title = "爱善天使互联网";
    this.init();
  },
  methods: {
    // 获取音乐数据
    init() {
      this.axios.get("/mock/yp1.json").then((res) => {
        this.my_data = res.data;
      });
      this.axios.get("/mock/ac1.json").then((res) => {
        const data = res.data;
        this.mdata = data.splice(0, 6);
      });
    },

    // 分页器事件 //根据当前页获取数据
    msgListView(curPage) {
      this.curPage = curPage;
      // // console.log(this.curPage);
      // this.init();
      if (this.curPage == "1") {
        this.init();
      } else if (this.curPage == "2") {
        this.init2();
      } else if (this.curPage == "3") {
        this.init3();
      } else if (this.curPage == "4") {
        this.init4();
      } else if (this.curPage == "5") {
        this.init5();
      } else if (this.curPage == "6") {
        this.init6();
      }
    },

    // 收费逻辑
    gopay() {
      if (this.state == 0) {
        // this.$router.push("/pay");
        this.showModal = true;
        console.log(this.showModal);
      }
      // 未登录
      if (this.state !== 0) {
        alert("点击登录去购买~");
        this.$router.push({ path: "/login", query: { k: 1 } });
        return;
      }
    },
    // 跳转 逻辑 封装
    goDays(methods, id) {
      this[methods](id);
    },
    // 跳转药品详情
    goDetails(id) {
      this.$router.push({
        path: "/sound2/:" + id,
        query: { id: id },
      });
    },
    // 跳转资讯详情
    goDetails2(id) {
      this.$router.push({
        path: "/sound/:" + id,
        query: { type: id },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
@import url("https://www.jq22.com/jquery/bootstrap-3.3.4.css");

.index {
  width: 100%;
  position: relative;

  // 关于我们区块
  .us-con {
    width: 100%;
    height: 400px;
    margin: 40px 0;

    .w {
      height: 100%;
      width: 1226px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 0;
      box-sizing: border-box;

      // 左侧
      .us-l {
        width: 60%;
        height: 100%;

        h3 {
          font-size: 38px;
          color: #888;
          margin-top: 10px;
        }

        h2 {
          font-size: 34px;
        }

        p {
          font-size: 12px;
          color: #888;
          line-height: 38px;
          margin-top: 15px;
        }

        .us-btn {
          width: 100px;
          height: 40px;
          background-color: $colorZ;
          color: #fff;
          line-height: 40px;
          text-align: center;
          cursor: pointer;
          transition: all 0.4s;

          &:hover {
            border-radius: 20px;
          }
        }
      }

      // 右侧
      .us-r {
        width: 40%;
        height: 100%;
        margin-right: 30px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  // 容器
  .yp-con {
    width: 100%;
    // background: url("/imgs/bg2.jpg") no-repeat center;
    background-size: cover;
    padding-bottom: 30px;
    box-sizing: border-box;

    .my-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      padding: 20px 40px;
      box-sizing: border-box;

      // 循环项
      .yp-item {
        width: 298px;
        height: 340px;
        // background-color: pink;
        margin: 20px;

        // 图片
        .item-img {
          width: 100%;
          height: 298px;
          overflow: hidden;

          &:hover {
            img {
              transform: scale(1.1);
            }
          }

          img {
            width: 100%;
            height: 100%;
            transition: all 0.2s;
          }
        }

        // 标题
        p {
          color: #333;
          cursor: pointer;
          text-align: center;
          width: 100%;
          font-weight: bold;
          transition: all 0.2s;
          margin-top: 10px;

          &:hover {
            color: $colorZ;
          }

          font-size: 14px;
        }
      }
    }

    .index-title {
      width: 100%;
      text-align: left;
      margin: 30px 0;
      margin-top: 80px;

      h4 {
        font-size: 16px;
        color: rgb(102, 102, 102);
        font-weight: bold;
        letter-spacing: 2px;
      }

      h2 {
        font-size: 24px;
        color: $colorZ;
        margin: 8px 0;
        letter-spacing: 2px;
      }

      p {
        font-size: 14px;
        color: #888;
      }
    }

    // 按钮
    .yc-btn {
      display: block;
      overflow: hidden;
      width: 50%;
      height: 44px;
      margin: 10px auto;
      cursor: pointer;
      text-align: center;
      line-height: 44px;
      color: #fff;
      background-color: $colorZ;
      border: 1px solid #fff;
      font-size: 14px;
      transition: all 0.5s;
      text-decoration: none;

      // font-weight: bold;
      &:hover {
        background-color: $colorZ;
        border: 1px solid $colorZ;
        border-radius: 30px;
        width: 80%;
      }

      span {
        margin-left: 8px;
      }
    }
  }

  // 标题
  .index-title {
    width: 100%;
    text-align: left;
    margin: 80px 0;

    // margin-top: 80px;
    h4 {
      font-size: 16px;
      color: rgb(102, 102, 102);
      font-weight: bold;
      letter-spacing: 2px;
    }

    h2 {
      font-size: 24px;
      color: $colorZ;
      margin: 8px 0;
      letter-spacing: 2px;
    }

    p {
      font-size: 14px;
      color: #888;
    }
  }

  // 资讯区块2
  .a2 {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: space-between;
    margin: 60px 0;

    .container {

      // 右侧
      .a2-r {
        width: 100%;
        height: 100%;

        // background-color: pink;
        .r-title {
          width: 100%;
          height: 50px;
          border-left: 3px solid $colorZ;
          padding-left: 30px;
          box-sizing: border-box;
          border-bottom: 1px dashed #ccc;

          h2 {
            line-height: 50px;
            font-weight: 400;
            font-size: 18px;
            color: #333;
          }
        }

        // 循环项
        .r-item {
          width: 100%;
          height: 160px;
          // background: #c60023;
          margin: 20px 0;
          display: flex;
          align-items: center;
          border-bottom: 2px dashed #ccc;
          transition: all 0.2s;
          // border-top: 2px solid #fff;
          cursor: pointer;
          border-top-width: 1;

          &:hover {
            background-color: #eee;

            .item-info {
              h2 {
                color: #333;
              }

              .blink {
                width: 100%;
              }

              p {
                font-size: 12px;
                width: 100%;
                text-overflow: -o-ellipsis-lastline;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                line-clamp: 2;
                -webkit-box-orient: vertical;
              }
            }
          }

          .item-img {
            width: 300px;
            height: 100%;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .item-info {
            margin-right: 30px;
            width: 70%;
            transition: all 0.4s;
            height: 100%;
            padding-top: 35px;
            box-sizing: border-box;
            position: relative;
            padding-left: 20px;

            .blink {
              transition: all 0.4s;
              width: 0px;
              height: 2px;
              background-color: #00bfb0;
              position: absolute;
              top: 0;
              left: 0;
            }

            span {
              font-size: 12px;
              margin-right: 15px;
              color: #888;

              i {
                font-weight: 400;
                margin-right: 5px;
                font-size: 14px;
              }
            }

            h2 {
              font-size: 16px;
              color: #333;
              margin: 10px auto;
              transition: all 0.2s;
            }

            p {
              font-size: 12px;
              color: #888;
              line-height: 30px;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 3;
              overflow: hidden;
            }
          }
        }
      }
    }
  }
}</style>
