<template>
  <div class="ac2">
    <div class="banner">
      <img src="/imgs/n2.jpg" alt="" />
    </div>

    <!-- 面包屑导航 -->
    <div class="ac1-list">
      <div class="my-container">
        <h2>所在位置：</h2>
        <a href="/">首页</a> <span>></span> <a href="/#/ac2">会员中心</a>
      </div>
    </div>

    <!-- 容器 -->
    <div class="ac2-con">
      <div class="my-container">
        <img src="/imgs/hy1.jpg" alt="" @click="gopa()" />
        <img src="/imgs/hy2.jpg" alt="" @click="gopa2()" />
        <img src="/imgs/hy3.jpg" alt="" @click="gopa3()" />
        <img src="/imgs/hy4.jpg" alt="" @click="gopa4()" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      m1: "59",
      m2: "99",
      m3: "199",
      m4: "299",
    };
  },
  methods: {
    gopa() {
      this.$router.push({ path: "/pay", query: { key: this.m1 } });
    },
    gopa2() {
      this.$router.push({ path: "/pay", query: { key: this.m2 } });
    },
    gopa3() {
      this.$router.push({ path: "/pay", query: { key: this.m3 } });
    },
    gopa4() {
      this.$router.push({ path: "/pay", query: { key: this.m4 } });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
.ac2 {
  .banner {
    width: 100%;
    height: 450px;
    margin-bottom: 80px;
    img {
      width: 100%;
      height: 100%;
    }
  }

  // 面包屑
  .ac1-list {
    width: 100%;
    margin: 30px 0;
    border-bottom: 1px dashed #ccc;
    padding-bottom: 15px;
    box-sizing: border-box;
    .my-container {
      display: flex;
      align-items: center;
      height: 100%;
      h2 {
        font-size: 21px;
      }

      a {
        font-size: 18px;
        // font-weight: bold;
        color: #333;
        text-decoration: none;
        &:nth-child(4) {
          color: $colorZ;
        }
      }

      span {
        font-size: 18px;
        color: #333;
        margin: 0 15px;
      }
    }
  }

  //   容器
  .ac2-con {
    width: 100%;
    height: 400px;
    background: #fafafa;
    .my-container {
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        width: 300px;
        height: 300px;
        margin: 15px 10px;
        cursor: pointer;
        transition: all 0.3s;
        &:hover {
          transform: translateY(-10px);
        }
      }
    }
  }
}
</style>