<template>
  <div class="nav-footer">
    <!-- 上层 -->
    <!-- <div class="s">
      <div class="container">
        <a href="/">网站首页</a> <span>|</span>
        <a href="/#/info">关于我们</a> <span>|</span>
        <a href="/#/hy">尊贵会员</a> <span>|</span>
        <a href="/#/zw">会员管理制度</a> <span>|</span>
        <a href="/#/zw2">纠纷处理</a> <span>|</span>
        <a href="/#/jf">用户协议</a> 
      </div>
    </div> -->

    <!-- 下层 -->
    <div class="x">
      <div class="container">
        <p>

          <a href="https://www.shdf.gov.cn/shdf/channels/740.html">扫黄打非入口</a>
          <a href="http://cyberpolice.mps.gov.cn/wfjb/">网络110报警服务</a>
          <a href="https://www.12377.cn/">中国互联网举报中心</a>
          <a href="https://www.12377.cn/">网络有害信息举报</a>
          <a href="https://www.12377.cn/node_548446.htm">网络举报APP下载</a>
        </p>


        <p>
          <a href="javascript:;">湖南爱善天使互联网有限公司</a>
          <a href="javascript:;">联系电话：18670356814</a>
          <a href="javascript:;">邮箱：luopeng@aishangroup.com</a>
          <a href="javascript:;">注册地址：湖南省长沙市岳麓区洋湖街道潇湘南路一段208号柏利大厦写字楼13001号</a>
        </p>
        <p>

          © 2022-2023 版权保护声明：
          <a href="https://beian.miit.gov.cn/#/Integrated/index">网站备案：湘ICP备16006617号-5</a>
        </p>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "nav-header",
  props: {},
  data() {
    return {
      // 网页的基础数据配置
      dataSourc: {},
    };
  },
  mounted() {
    // 查询网站基本配置数据
    this.axios.get("http://8.142.155.68:8099/api/data/sourc/selectAll").then((res) => {
      this.dataSourc = res.data[0];
    });
  },
};
</script>

<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";

.nav-footer {
  background-color: #1d1d1d;
  padding: 15px 0 25px 0;
  box-sizing: border-box;
  .s {
    width: 100%;
    height: 48px;
    border-bottom: 1px solid #3d424a;

    .container {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      a {
        color: #b1b3b9;
        text-decoration: none;

        &:hover {
          color: #009af3;
        }
      }

      span {
        color: #b1b3b9;
        margin: 0 15px;
      }
    }

  }

  .x {
    width: 100%;
    margin-top: 20px;
    text-align: center;

    p {
      width: 100%;
      height: 15px;
      color: #b1b3b9;
      margin: 15px 0;

      a {
        color: #b1b3b9;
        text-decoration: none;
        margin: 0 15px;

        &:hover {
          color: #009af3;
        }
      }
    }
  }
}
</style>
