<template>
  <div class="ac2" style="width: 100%">
    <!-- banner -->
    <div class="banner" style="width: 100%">
      <img src="/imgs/ct.jpg" style="width: 100%" />
    </div>
    <!-- 顶宽布局 -->
    <div class="container">
      <!-- 标题 -->
      <div class="a2-title">
        <h3>关于我们</h3>
        <div class="blink"></div>
      </div>
      <!-- 容器 -->
      <div class="a2-con">
        <div class="a2-l">
          <div class="a2-box">
            <p>
              湖南爱善天使互联网有限公司成立于2016年04月20日，注册地位于湖南省长沙市岳麓区洋湖街道潇湘南路一段208号柏利大厦写字楼13001号
            </p>
            <p>
              经营范围包括许可项目：互联网信息服务；药品互联网信息服务；第二类增值电信业务；食品销售；食品互联网销售。（依法须经批准的项目，经相关部门批准后方可开展经营活动，具体经营项目以相关部门批准文件或许可证件为准）
            </p>
            <p>
              一般项目：信息咨询服务（不含许可类信息咨询服务）；信息技术咨询服务；网络技术服务；技术服务、技术开发、技术咨询、技术交流、技术转让、技术推广；数据处理和存储支持服务；广告制作；广告设计、代理；广告发布；非居住房地产租赁；货物进出口；市场营销策划；互联网销售（除销售需要许可的商品）；第二类医疗器械销售。（除依法须经批准的项目外，凭营业执照依法自主开展经营活动）
            </p>
          </div>
        </div>
        <div class="a2-r"></div>
      </div>
      <!-- 文本区 -->
      <div class="a2-info">
        <!-- 价值观 -->
        <div class="a2-item">
          <h3>联系我们:</h3>
          <p>公司名称：湖南爱善天使互联网有限公司</p>
          <p>公司电话：18670356814</p>
          <p>公司邮箱：luopeng@aishangroup.com</p>
          <p>网站域名：www.asts.shop</p>
          <p>公司地址：湖南省长沙市岳麓区洋湖街道潇湘南路一段208号柏利大厦写字楼13001号</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "swiper/css/swiper.css";

export default {
  name: "ac1",
  components: {},
  data() {
    return {};
  },
  mounted() {
    document.title = "爱善天使互联网";
  },
};
</script>

<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";

.ac2 {
  .banner {
    width: 100%;
    height: auto;
    margin-bottom: 30px;
  }

  .container {
    // height: 1000px;
    margin: 60px auto;
    padding-top: 30px;
    box-sizing: border-box;

    // 标题
    .a2-title {
      text-align: center;

      h3 {
        font-size: 21px;
        color: #00bfb0;
      }

      .blink {
        width: 250px;
        height: 1px;
        background-color: #00bfb0;
        margin: 5px auto;
      }
    }

    // 容器
    .a2-con {
      width: 100%;
      height: 500px;
      //   background-color: pink;
      box-sizing: border-box;
      @include flex();

      // 左侧
      .a2-l {
        width: 50%;
        height: 100%;
        // background-color: red;
        position: relative;

        .a2-box {
          width: 80%;
          height: 80%;
          background-color: #4b80b7;
          position: absolute;
          right: 0;
          top: 50px;
          color: #fff;
          padding: 20px;
          box-sizing: border-box;

          p {
            font-size: 13px;
            margin: 30px auto;
          }
        }
      }

      // 右侧
      .a2-r {
        width: 50%;
        height: 100%;
        // background-color: yellow;
        background: url("/imgs/us.jpg") no-repeat center;
        background-size: cover;
      }
    }

    // 文本区
    .a2-info {
      margin-top: 30px;

      .a2-item {

        // margin-bottom: 60px;
        h3 {
          font-size: 24px;
          margin-bottom: 10px;
        }

        p {
          font-size: 14px;
          margin: 10px auto;
        }
      }
    }
  }
}</style>
