<template>
  <div class="ac1">
    <div class="banner">
      <img src="/imgs/n1.jpg" alt="" />
    </div>

    <!-- 面包屑导航 -->
    <div class="ac1-list">
      <div class="my-container">
        <h2>所在位置：</h2>
        <a href="/">首页</a> <span>></span> <a href="/#/ac1">药品中心</a>
      </div>
    </div>

    <!-- 容器 -->
    <div class="yp-con">
      <div class="my-container">
        <!-- 循环项 -->
        <div
          class="yp-item"
          v-for="(item, index) in this.my_data"
          :key="index"
          @click="goDetails(item.id)"
        >
          <!-- 图片 -->
          <div class="item-img">
            <img :src="item.img" alt="" />
          </div>
          <p>{{ item.title }}</p>
        </div>
      </div>
    </div>

    <!-- 分页器存放容器 -->
    <div class="my-container">
      <!-- 分页器 -->
      <navigation
        :pages="pages"
        :current.sync="pageNo"
        @navpage="msgListView"
      ></navigation>
    </div>
  </div>
</template>

<script>
import navigation from "./../components/navigation";
export default {
  data() {
    return {
      state: JSON.parse(window.localStorage.getItem("state")),
      href: "index",
      // 音乐列表
      songList: [],
      // 总页数
      pages: 2,
      // 当前页
      pageNo: 1,
      // 点击页数
      curPage: 1,
      // 根据页数获取数据
      my_data: [],
      drawer: false,

      showModal: false,
    };
  },
  components: {
    navigation,
    // as,

    // Ma,
    // "a-player": VueAplayer,
  },
  mounted() {
    document.title = "爱善天使互联网";
    this.init();
  },
  methods: {
    // 获取音乐数据
    init() {
      this.axios.get("/mock/yp1.json").then((res) => {
        this.my_data = res.data;
      });
    },

    init2() {
      this.axios.get("/mock/yp2.json").then((res) => {
        this.my_data = res.data;
      });
    },
    //  init3() {
    //   this.axios.get("/mock/yp3.json").then((res) => {
    //     this.my_data = res.data;
    //   });
    // },

    // 分页器事件 //根据当前页获取数据
    msgListView(curPage) {
      this.curPage = curPage;
      // // console.log(this.curPage);
      // this.init();
      if (this.curPage == "1") {
        this.init();
      } else if (this.curPage == "2") {
        this.init2();
      } else if (this.curPage == "3") {
        this.init3();
      } else if (this.curPage == "4") {
        this.init4();
      } else if (this.curPage == "5") {
        this.init5();
      } else if (this.curPage == "6") {
        this.init6();
      }
    },

    // 收费逻辑
    gopay() {
      if (this.state == 0) {
        // this.$router.push("/pay");
        this.showModal = true;
        console.log(this.showModal);
      }
      // 未登录
      if (this.state !== 0) {
        alert("点击登录去购买~");
        this.$router.push({ path: "/login", query: { k: 1 } });
        return;
      }
    },
    // 跳转 逻辑 封装
    goDays(methods, id) {
      this[methods](id);
    },
    // 跳转详情页
    goDetails(id) {
      this.$router.push({
        path: "/sound2/:" + id,
        query: { id: id },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
.ac1 {
  // banner
  .banner {
    width: 100%;
    height: 450px;
    margin-bottom: 80px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  // 面包屑
  .ac1-list {
    width: 100%;
    margin: 30px 0;
    border-bottom: 1px dashed #ccc;
    padding-bottom: 15px;
    box-sizing: border-box;
    .my-container {
      display: flex;
      align-items: center;
      height: 100%;
      h2 {
        font-size: 21px;
      }

      a {
        font-size: 18px;
        // font-weight: bold;
        color: #333;
        text-decoration: none;
        &:nth-child(4) {
          color: $colorZ;
        }
      }

      span {
        font-size: 18px;
        color: #333;
        margin: 0 15px;
      }
    }
  }

  // 容器
  .yp-con {
    width: 100%;
    // height: 700px;
    // background-color: #c60223;
    // background: url("/imgs/bg2.jpg") no-repeat center;
    background-size: cover;
    padding-bottom: 30px;
    box-sizing: border-box;
    padding-top: 30px;
    .my-container {
      display: flex;
      // justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      padding: 20px 40px;
      box-sizing: border-box;
      // 循环项
      .yp-item {
        width: 298px;
        height: 340px;
        // background-color: pink;
        margin: 20px 30px;
        // 图片
        .item-img {
          width: 200px;
          height: 200px;
          overflow: hidden;
          text-align: center;
          margin: 0 auto;
          &:hover {
            img {
              transform: scale(1.1);
            }
          }
          img {
            width: 100%;
            // height: 100%;
            transition: all 0.2s;
          }
        }
        // 标题
        p {
          color: #333;
          cursor: pointer;
          text-align: center;
          width: 100%;
          font-weight: bold;
          transition: all 0.2s;
          margin-top: 10px;
          font-size: 14px;
          &:hover {
            color: $colorZ;
          }
        }
      }
    }

    .index-title {
      width: 100%;
      text-align: center;
      margin: 30px 0;
      margin-top: 80px;
      h4 {
        font-size: 16px;
        color: rgb(102, 102, 102);
        font-weight: bold;
        letter-spacing: 2px;
      }
      h2 {
        font-size: 24px;
        color: $colorZ;
        margin: 8px 0;
        letter-spacing: 2px;
      }

      p {
        font-size: 14px;
        color: #888;
      }
    }

    // 按钮
    .yc-btn {
      display: block;
      overflow: hidden;
      width: 150px;
      height: 44px;
      margin: 10px auto;
      cursor: pointer;
      text-align: center;
      line-height: 44px;
      color: #fff;
      border: 1px solid #fff;
      font-size: 14px;
      transition: all 0.5s;
      text-decoration: none;
      // font-weight: bold;
      &:hover {
        background-color: $colorZ;
        border: 1px solid $colorZ;
      }
      span {
        margin-left: 8px;
      }
    }
  }
}
</style>
